'use client';
import React from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { ValidationErrorMessage } from './ValidationErrorMessage';
import { saveEnquiry } from '@/app/services/enquiryService';
import useThemeStore from '@/app/stores/useThemeStore';
import { usePathname } from 'next/navigation';
import { useGlobalStore } from '@/app/stores/useGlobalStore';
import { cn } from '@/lib/utils';

interface FormProps {
  formType: string;
  setOpenPopup?: (isOpen: boolean) => void;
  formLabel?: string;
  cta?: string;
  buttonTitle?: string;
  contactFormbuttonTitle?: string;
  contactFormbuttonBg?: string;
}

interface EnquiryPayload {
  name: string;
  phone: string;
  message: string;
  ctaLabel: string;
}

function GenericForm({
  formType,
  cta = '',
  buttonTitle,
  contactFormbuttonTitle,
}: FormProps) {
  const { ctaLabel, setCtaLabel } = useGlobalStore();
  const { setShowToast } = useThemeStore((state) => state);

  const pathname = usePathname();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<EnquiryPayload>();

  const onSubmit = async ({ name, phone, message }: EnquiryPayload) => {
    const categoryContext = process.env.NEXT_PUBLIC_FORM_CATEGORY_CONTEXT;
    const enquiryContext = process.env.NEXT_PUBLIC_FORM_ENQUIRY_CONTEXT;
    let payload = {
      name: name,
      sourceId: parseInt(process.env.NEXT_PUBLIC_FORM_SOURCE_ID!),
      phoneNumber: '+91' + phone,
      platform: process.env.NEXT_PUBLIC_FORM_PLATFORM,
      categoryContext: categoryContext!?.length ? categoryContext : null,
      enquiryContext: enquiryContext!?.length ? enquiryContext : null,
      notes: message,
      url: `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`,
      ctaLabel: cta,
    };

    saveEnquiry(payload)
      .then(() => {
        reset();
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      })
      .catch((e) => console.error(e.message));
  };

  if (formType === 'CONTACT_FORM') {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputName
          errors={errors}
          restProps={{
            ...register('name', {
              required: 'Name is Required',
              pattern: {
                value: /^[A-Za-z ]+$/i,
                message: 'Name is not valid',
              },
            }),
          }}
        />
        <InputPhoneNumber
          errors={errors}
          restProps={{
            ...register('phone', {
              required: 'Phone is required',
              pattern: {
                value: /^[0-9]+$/,
                message: 'invalid phone number',
              },
              min: {
                value: 1000000000,
                message: 'Phone number is too short',
              },
              max: {
                value: 9999999999,
                message: 'Phone number is too long',
              },
            }),
          }}
        />

        <input
          id='ctaLabel'
          type='text'
          hidden
          value={cta ? cta : ctaLabel}
          readOnly
          {...register('ctaLabel')}
        />
        <InputTextArea
          restProps={{
            ...register('message', {
              pattern: {
                value: /^[A-Za-z ]+$/i,
                message: 'Name is not valid',
              },
            }),
          }}
        />

        <button
          type='submit'
          className={cn(
            ` col-span-2 mt-[12px] h-[50px] w-full rounded-[12px] bg-[#00b4b9] text-white hover:bg-[#1e2636] focus:bg-[#1e2636] lg:bg-[#1e2636]  lg:hover:bg-[#00b4b9]`
          )}
        >
          {buttonTitle ? buttonTitle : 'Submit'}
        </button>
      </form>
    );
  }

  //
  if (formType === 'APPOINMENT_FORM') {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='mb-[20px] mt-[42px] grid  w-[490px] grid-cols-2 gap-x-[15px] rounded-[20px] border bg-white  p-[26px]'
      >
        <div className='col-span-1'>
          <InputName
            errors={errors}
            restProps={{
              ...register('name', {
                required: 'Name is Required',
                pattern: {
                  value: /^[A-Za-z ]+$/i,
                  message: 'Name is not valid',
                },
              }),
            }}
          />
        </div>
        <div className='col-span-1'>
          <InputPhoneNumber
            errors={errors}
            restProps={{
              ...register('phone', {
                required: 'Phone is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'invalid phone number',
                },
                min: {
                  value: 1000000000,
                  message: 'Phone number is too short',
                },
                max: {
                  value: 9999999999,
                  message: 'Phone number is too long',
                },
              }),
            }}
          />
        </div>
        <div className='col-span-2'>
          <InputTextArea
            restProps={{
              ...register('message', {
                pattern: {
                  value: /^[A-Za-z ]+$/i,
                  message: 'Name is not valid',
                },
              }),
            }}
          />
        </div>
        <button
          type='submit'
          className={cn(
            'col-span-2 mt-3 h-[50px] w-full rounded-[12px]  bg-[#00b4b9] text-white hover:bg-[#1e2636]  focus:bg-[#1e2636] max-xl:h-[35px] max-xl:text-[10px] lg:bg-[#1e2636] lg:hover:bg-[#00b4b9]'
          )}
        >
          {contactFormbuttonTitle ? contactFormbuttonTitle : 'Book a Callback'}
        </button>
      </form>
    );
  }
}

export default GenericForm;

const InputName = ({
  errors,
  restProps,
}: {
  errors: FieldErrors<EnquiryPayload>;
  restProps: any;
}) => {
  return (
    <>
      <input
        id='name'
        type='text'
        placeholder='Name'
        className={`max-3xl:h-[50px] h-[50px] w-full rounded-[10px] border bg-white px-[14px] placeholder:text-[black] focus:outline-none dark:border-gray-200  dark:bg-transparent max-2xl:h-[46px]  max-sm:h-[50px] lg:text-[16px]`}
        {...restProps}
      />
      <div className='flex h-[20px] items-center'>
        {errors?.name && (
          <ValidationErrorMessage>
            {errors?.name?.message}
          </ValidationErrorMessage>
        )}
      </div>
    </>
  );
};
const InputPhoneNumber = ({
  errors,
  restProps,
}: {
  errors: FieldErrors<EnquiryPayload>;
  restProps: any;
}) => {
  return (
    <>
      <div
        className={`max-3xl:h-[50px] flex h-[50px] w-full items-center justify-start rounded-[10px] border bg-white px-[14px] placeholder:text-[black] focus:outline-none dark:border-gray-200  dark:bg-transparent max-2xl:h-[46px]  max-sm:h-[50px] lg:text-[16px]`}
      >
        <p className='flex min-w-[40px] flex-nowrap text-[#000]'>+91 |</p>
        <input
          id='phone'
          type='tel'
          maxLength={10}
          placeholder='Mobile Number'
          className={`max-3xl:h-[55px] ml-[10px] h-[55px] rounded-[0_10px_10px_0] bg-transparent  text-[16px] placeholder:text-[black] focus:outline-none max-2xl:h-[55px] max-sm:h-[55px]`}
          {...restProps}
        />
      </div>
      <div className='flex h-[20px] items-center'>
        {errors?.phone && (
          <ValidationErrorMessage>
            {errors?.phone?.message}
          </ValidationErrorMessage>
        )}
      </div>
    </>
  );
};

const InputTextArea = ({ restProps }: { restProps: any }) => {
  return (
    <textarea
      id='message'
      placeholder='Send Message'
      className={` w-full  resize-none rounded-[10px] border bg-white p-[14px] placeholder:text-[black] focus:outline-none dark:border-gray-200  dark:bg-transparent lg:text-[16px]`}
      rows={3}
      {...restProps}
    />
  );
};

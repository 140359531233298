import { Dialog, DialogContent } from '@/components/ui/dialog';

type Props = {
  children: React.ReactNode;
  open: boolean;
  setOpenPopup: () => void;
};

function ModalComponent({ children, open, setOpenPopup }: Props) {
  return (
    <Dialog open={open} onOpenChange={setOpenPopup}>
      <DialogContent className=' border-none'>{children}</DialogContent>
    </Dialog>
  );
}

export default ModalComponent;
